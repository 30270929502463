import { BoardPreview, IBoard } from '@vestaboard/installables'
import {
  ButtonBase,
  Card,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { Alert } from '@material-ui/lab'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { DraggableTableRow } from '../../../ui/DraggableTableRow'
import { DroppableTableBody } from '../../../ui/DropableTableBody'
import { FormattedDateTime } from '../../formatted-date-time'
import { Link } from 'react-router-dom'
import { ListCategoryColorTemplates_colorTemplateCategory_colorTemplates } from '../hooks/__generated__/ListCategoryColorTemplates'
import { TruncatedId } from '../../truncated-id'
import { makeStyles } from '@material-ui/styles'
import { useListCategoryColorTemplates } from '../hooks/useListCategoryColorTemplates'
import { useSetColorTemplateSortOrders } from '../hooks/useSetColorTemplateSortOrders'

const useStyles = makeStyles({
  chips: {
    '& > *': {
      margin: 3
    }
  }
})

interface IColorTemplates {
  id: string
  title: string
}

export const ColorTemplates = (props: IColorTemplates) => {
  const perPage = 50
  const classes = useStyles()
  const history = useHistory()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const page = parseInt(query.get('page') || '0')
  const [colorTemplates, setColorTemplates] = useState<
    ListCategoryColorTemplates_colorTemplateCategory_colorTemplates[]
  >([])
  const [setSortOrdersMutation] = useSetColorTemplateSortOrders()
  const { data, loading, error } = useListCategoryColorTemplates({
    page: 0,
    perPage: 5000,
    id: props.id
  })

  const onDragEnd = useCallback(
    async result => {
      if (!result.destination) {
        return
      }

      const startIndex = result.source.index
      const endIndex = result.destination.index

      const newColorTemplates = Array.from(colorTemplates)
      const [removed] = newColorTemplates.splice(startIndex, 1)
      newColorTemplates.splice(endIndex, 0, removed)

      setColorTemplates(newColorTemplates)

      await setSortOrdersMutation({
        variables: {
          input: {
            colorTemplateCategoryId: props.id,
            colorTemplateIds: newColorTemplates.map(colorTemplate => colorTemplate.id)
          }
        }
      })
    },
    [colorTemplates, setSortOrdersMutation, props.id]
  )

  useEffect(() => {
    if (data?.colorTemplateCategory?.colorTemplates) {
      setColorTemplates(data?.colorTemplateCategory?.colorTemplates)
    }
  }, [data])

  const visibleColorTemplates = useMemo(() => {
    const startIndex = perPage * page
    const endIndex = page * perPage + perPage
    return (colorTemplates || []).slice(startIndex, endIndex)
  }, [colorTemplates, page])

  return error ? (
    <Alert severity='error'>There was an error loading the color templates</Alert>
  ) : loading || !data ? (
    <CircularProgress />
  ) : colorTemplates?.length ? (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 50 }} />
            <TableCell>Color Template ID</TableCell>
            <TableCell>Created On</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody component={DroppableTableBody(onDragEnd)}>
          {visibleColorTemplates.map((colorTemplate, index) => (
            <TableRow key={colorTemplate.id} component={DraggableTableRow(colorTemplate.id, index)}>
              <TableCell>
                <DragIndicatorIcon />
              </TableCell>
              <TableCell>
                <TruncatedId value={colorTemplate.id} path={`/color-templates/${colorTemplate.id}`} />
              </TableCell>
              <TableCell>
                <FormattedDateTime format='MM/DD/YYYY' value={colorTemplate.created} />
              </TableCell>
              <TableCell className={classes.chips}>
                {colorTemplate?.category?.id ? (
                  <ButtonBase
                    key={colorTemplate?.category.id}
                    onClick={() => {
                      history.push(`/color-templates/categories/${colorTemplate?.category?.id || ''}`)
                    }}>
                    {<Chip label={colorTemplate?.category.title} variant='outlined' />}
                  </ButtonBase>
                ) : null}
              </TableCell>
              <TableCell>
                <Link to={`/color-templates/${colorTemplate.id}`}>
                  {colorTemplate.message.formatted ? (
                    <BoardPreview characters={colorTemplate.message.formatted as IBoard} />
                  ) : null}
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {data.colorTemplateCategory.colorTemplates.length ? (
        <TablePagination
          rowsPerPageOptions={[]}
          count={data.colorTemplateCategory.colorTemplates.length}
          rowsPerPage={perPage}
          page={page}
          onPageChange={(_, page) => history.push(`/color-templates/categories/${props.id}?page=${page}`)}
          onChangeRowsPerPage={() => {}}
        />
      ) : null}
    </Card>
  ) : null
}
