import { ButtonBase, Chip } from '@material-ui/core'
import {
  ListPurchases,
  ListPurchasesVariables,
  ListPurchases_listPurchases_purchases
} from './__generated__/ListPurchases'
import { PurchaseSortBy, PurchaseSortOrder } from './types'
import React, { useState } from 'react'

import { AdvancedSearch } from '../../ui/AdvancedSearch'
import { Check } from '@material-ui/icons'
import { CsvExport } from '../../ui/CsvExport'
import { FormattedDateTime } from '../formatted-date-time'
import { SuperGrid } from '../../ui/SuperGrid'
import { TruncatedId } from '../truncated-id'
import { gql } from 'apollo-boost'
import moment from 'moment'
import { useInvalidateActivationCode } from '../plus/hooks/useInvalidateActivationCode'
import { useQuery } from '@apollo/react-hooks'
import { useUnInvalidateActivationCode } from '../plus/hooks/useUnInvalidateActivationCode'

const PLUS_PRODUCT_QUERY = gql`
  query ListPurchases(
    $terms: String
    $page: Int
    $perPage: Int
    $sortBy: PurchaseSortBy
    $sortOrder: PurchaseSortOrder
    $activationCodeState: String
  ) {
    listPurchases(
      terms: $terms
      perPage: $perPage
      page: $page
      sortBy: $sortBy
      sortOrder: $sortOrder
      activationCodeState: $activationCodeState
    ) {
      purchases {
        id
        optOutOfRenewal
        account {
          id
          person {
            id
          }
        }
        emailAddress
        created
        purchaseSession {
          id
          shopifyOrderId
          shopifyOrderName
        }
        lineItems {
          id
          activationCode {
            id
            code
            isInvalidated
          }
          productApplication {
            id
            nextBillingDate
            board {
              id
            }
          }
        }
      }
      pagination {
        page
        total
        perPage
      }
    }
  }
`

interface FlatPurchase {
  purchaseId: string
  personId?: string
  email?: string
  purchaseDate: number
  shopifyOrderId?: string
  shopifyOrderName?: string
  productApplicationId?: string
  optOutOfRenewal: boolean
  boardId?: string
  nextBillingDate?: number
  activationCodes: {
    id: string
    code: string
    isInvalidated: boolean
  }[]
}

export const OrdersListPage = () => {
  const perPage = 50
  const [sort, setSort] = useState<{
    by: PurchaseSortBy
    order: PurchaseSortOrder
  }>({
    by: PurchaseSortBy.created,
    order: PurchaseSortOrder.desc
  })
  const [activationCodeState, setActivationCodeState] = useState('all')
  const [searchValue, setSearchValue] = useState<string | null>(null)
  const [page, setPage] = useState(0)
  const variables = {
    terms: (searchValue || '').trim(),
    sortBy: sort.by,
    sortOrder: sort.order,
    activationCodeState: activationCodeState === 'all' ? null : activationCodeState
  }
  const { data, loading, error } = useQuery<ListPurchases, ListPurchasesVariables>(PLUS_PRODUCT_QUERY, {
    variables: {
      ...variables,
      perPage,
      page
    }
  })
  const [invalidateActivationCode] = useInvalidateActivationCode()
  const [unInvalidateActivationCode] = useUnInvalidateActivationCode()

  const plusPurchases = data?.listPurchases.purchases || []
  const pagination = data?.listPurchases?.pagination

  const columns = [
    {
      title: 'Order ID',
      key: PurchaseSortBy.id,
      render: (plusApplication: FlatPurchase) =>
        plusApplication.purchaseId ? (
          <TruncatedId value={plusApplication.purchaseId} path={`/orders/${plusApplication.purchaseId}`} />
        ) : null,
      width: 150
    },
    {
      title: 'Shopify Order ID',
      key: PurchaseSortBy.shopifyOrderId,
      render: (plusApplication: FlatPurchase) => (
        <a
          style={{
            color: '#3f51b5',
            fontSize: '1rem',
            textDecoration: 'none'
          }}
          target='_blank'
          rel='noopener noreferrer'
          href={`https://vestaboard-plus.myshopify.com/admin/orders/${plusApplication?.shopifyOrderId}`}>
          {plusApplication?.shopifyOrderId || ''}
        </a>
      ),
      width: 150
    },
    {
      title: 'Shopify Order Name',
      key: PurchaseSortBy.shopifyOrderName,
      render: (plusApplication: FlatPurchase) => plusApplication?.shopifyOrderName,
      width: 150
    },
    {
      title: 'Purchase Date',
      key: PurchaseSortBy.created,
      render: (plusApplication: FlatPurchase) => (
        <FormattedDateTime format='MM/DD/YYYY' value={plusApplication.purchaseDate} />
      ),
      width: 120
    },
    {
      title: 'Billing Date',
      render: (plusApplication: FlatPurchase) => (
        <FormattedDateTime format='MM/DD/YYYY' value={plusApplication.nextBillingDate} />
      ),
      width: 120
    },
    {
      title: 'Person ID',
      key: PurchaseSortBy.personId,
      render: (plusApplication: FlatPurchase) =>
        plusApplication?.personId ? (
          <TruncatedId value={plusApplication?.personId || ''} path={`/person/${plusApplication?.personId}`} />
        ) : null,
      width: 150
    },
    {
      title: 'Person Email',
      key: PurchaseSortBy.email,
      render: (plusApplication: FlatPurchase) => plusApplication.email || '',
      width: 200
    },
    {
      title: 'Is Activated?',
      key: PurchaseSortBy.productApplicationId,
      render: (plusApplication: FlatPurchase) => (plusApplication.productApplicationId ? <Check /> : null),
      width: 100
    },
    {
      title: 'Subscription ID',
      key: PurchaseSortBy.productApplicationId,
      render: (plusApplication: FlatPurchase) =>
        plusApplication.productApplicationId ? (
          <TruncatedId
            value={plusApplication.productApplicationId}
            path={`/plus/${plusApplication.productApplicationId}`}
          />
        ) : null,
      width: 150
    },
    {
      title: 'Board ID',
      render: (plusApplication: FlatPurchase) =>
        plusApplication.boardId ? (
          <TruncatedId value={plusApplication.boardId} path={`/boards/${plusApplication.boardId}`} />
        ) : null,
      width: 150
    },
    {
      title: 'Opted Out of Renewal',
      render: (plusApplication: FlatPurchase) => (plusApplication.optOutOfRenewal ? <Check /> : null),
      width: 150
    },
    {
      title: 'Has Valid Codes',
      render: (plusApplication: FlatPurchase) =>
        plusApplication.activationCodes.filter(code => !code.isInvalidated).length ? <Check /> : null,
      width: 150
    },
    {
      title: 'Has Invalid Codes',
      render: (plusApplication: FlatPurchase) =>
        plusApplication.activationCodes.filter(code => !!code.isInvalidated).length ? <Check /> : null,
      width: 150
    },
    {
      title: 'Activation Codes',
      render: (plusApplication: FlatPurchase) => (
        <>
          {(plusApplication?.activationCodes || []).map(activationCode => (
            <Chip
              style={{
                marginBottom: 4
              }}
              color={activationCode?.isInvalidated ? 'secondary' : 'primary'}
              label={
                <>
                  {activationCode?.code}
                  {' | '}
                  <ButtonBase
                    onClick={async () => {
                      if (activationCode?.isInvalidated) {
                        await unInvalidateActivationCode({
                          variables: {
                            id: activationCode?.id || ''
                          }
                        })
                      } else {
                        await invalidateActivationCode({
                          variables: {
                            id: activationCode?.id || ''
                          }
                        })
                      }
                    }}>
                    {activationCode?.isInvalidated ? 'Uninvalidate' : 'Invalidate'}
                  </ButtonBase>
                </>
              }
              key={activationCode?.id}
            />
          ))}
        </>
      ),
      width: 250
    }
  ]

  const createFlatPurchase = (app: ListPurchases_listPurchases_purchases) =>
    ({
      purchaseId: app.id,
      personId: app?.account?.person?.id,
      email: app?.emailAddress,
      purchaseDate: app.created,
      shopifyOrderId: app.purchaseSession?.shopifyOrderId,
      shopifyOrderName: app.purchaseSession?.shopifyOrderName,
      productApplicationId: app?.lineItems?.[0]?.productApplication?.id,
      optOutOfRenewal: app.optOutOfRenewal,
      boardId: app?.lineItems?.[0]?.productApplication?.board?.id,
      nextBillingDate: app?.lineItems?.[0]?.productApplication?.nextBillingDate,
      activationCodes: app?.lineItems?.map(listItem => listItem.activationCode) || []
    } as FlatPurchase)

  const visiblePlusApplications = (plusPurchases || []).map(createFlatPurchase)

  return (
    <SuperGrid
      title='Plus Orders'
      setSort={setSort}
      columns={columns}
      rows={visiblePlusApplications}
      sortBy={sort.by}
      sortOrder={sort.order}
      pagination={pagination}
      setPage={setPage}
      error={!!error}
      loading={loading}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      renderSearch={
        <AdvancedSearch
          searchTypes={[
            {
              key: 'all',
              title: 'All',
              type: 'text' as 'text'
            },
            {
              key: 'Valid',
              title: 'Valid Unused',
              type: 'text' as 'text'
            },
            {
              key: 'Invalid',
              title: 'Invalid Unused',
              type: 'text' as 'text'
            },
            {
              key: 'Activated',
              title: 'Activated',
              type: 'text' as 'text'
            }
          ]}
          value={searchValue}
          setValue={value => {
            setPage(0)
            setSearchValue((value as string) || '')
          }}
          searchType={activationCodeState}
          setSearchType={setActivationCodeState}
        />
      }
      centerButtons={
        <CsvExport
          query={PLUS_PRODUCT_QUERY}
          variables={variables}
          getPagination={({ data }) => ({
            ...data.listPurchases.pagination,
            page: data.listPurchases.pagination.page + 1
          })}
          formatData={({ data }) => {
            return data.listPurchases.purchases.map(createFlatPurchase).map((order: FlatPurchase) => {
              const { activationCodes, ...rest } = order

              const invalidatedActivationCodes = activationCodes
                .filter(code => code.isInvalidated)
                .map(code => code.code)
                .join(', ')

              const validActivationCodes = activationCodes
                .filter(code => !code.isInvalidated)
                .map(code => code.code)
                .join(', ')

              return {
                ...rest,
                isActivated: !!order.productApplicationId,
                purchaseDate: order.purchaseDate ? moment(order.purchaseDate).format('YYYY-MM-DD hh:mm') : null,
                nextBillingDate: order.nextBillingDate
                  ? moment(order.nextBillingDate).format('YYYY-MM-DD hh:mm')
                  : null,
                invalidatedActivationCodes,
                validActivationCodes,
                hasValidActivationCodes: !!validActivationCodes?.length,
                hasInvalidatedActivationCodes: !!invalidatedActivationCodes?.length
              }
            })
          }}
        />
      }
    />
  )
}
