import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core'

import { ReplayOutlined } from '@material-ui/icons'
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet'
import WifiIcon from '@material-ui/icons/Wifi'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { DEVICE_QUERY, useKeypairTokenQuery } from '.'
import { FormattedDateTime } from '../formatted-date-time'
import { TruncatedId } from '../truncated-id'
import { CharacterSet } from './CharacterSet'
import { ConnectionCard } from './ConnectionCard'
import { DeviceModel } from './DeviceModel'
import { FirmwareVersion } from './FirmwareVersion'
import { DeviceQuery } from './__generated__/DeviceQuery'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { useToasts } from '@vestaboard/installables'

interface IDeviceInformation {
  deviceId: string
  data?: DeviceQuery
  makeEligibleForYocto: () => void
  sendLocalApiToken: () => void
  localApiEmailTriggered: boolean
  setPollInterval: (interval: number) => void
  refetch?: () => void
  setConfirmRemoveNetwork: (value: boolean) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    logsTitle: {
      paddingTop: 32,
      paddingBottom: 24
    },
    connectionCard: {
      marginTop: 32,
      padding: 24,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    },
    connectionCardSpacer: {
      width: 24
    }
  })
)

const REQUEST_HEARTBEAT_MUTATION = gql`
  mutation RequestHeartbeatMutation($deviceId: String!) {
    requestDeviceHeartbeat(input: { device: $deviceId }) {
      requested
    }
  }
`
const REFRESH_DEVICE_KEYS_MUTATION = gql`
  mutation RefreshDeviceKeys($deviceId: String!) {
    refreshDeviceKeys(input: { device: $deviceId }) {
      refreshRequested
    }
  }
`
const UPGRADE_FIRMWARE_MUTATION = gql`
  mutation RequestUpgradeFirmware($input: DeviceUpgradeFirmwareMutationInput!) {
    upgradeFirmware(input: $input) {
      success
      error
    }
  }
`

const QUERY_LATEST_FIRMWARE = gql`
  query DeployedOsVersion {
    osVersionToDeploy {
      id
      created
      metadata
    }
  }
`
export const useRequestHeartbeat = () => useMutation(REQUEST_HEARTBEAT_MUTATION)
export const useRefreshDeviceKeys = () => useMutation(REFRESH_DEVICE_KEYS_MUTATION)
export const useRequestFirmwareUpgrade = () => useMutation(UPGRADE_FIRMWARE_MUTATION)
const useLatestFirmware = () => useQuery(QUERY_LATEST_FIRMWARE, { pollInterval: 60000 })
const delay = (ms: number) => new Promise(res => setTimeout(res, ms))

export const Tab: React.FC<{ count: number }> = props => <>{Array(props.count).fill(<>&nbsp;&nbsp;&nbsp;</>)}</>

export const DeviceInformation = (props: IDeviceInformation) => {
  const classes = useStyles()
  const history = useHistory()
  const { addToast } = useToasts()

  const { data, setPollInterval } = props

  const [requestDeviceHeartbeat] = useRequestHeartbeat()
  const [refreshDeviceKeys] = useRefreshDeviceKeys()
  const [upgradeFirmware] = useRequestFirmwareUpgrade()
  const [capturedFirmwareUpgradeStatus, setCapturedFirmwareUpgradeStatus] = React.useState({
    status: 'idle',
    progress: 0
  })
  const { data: firmwareData } = useLatestFirmware()

  const dateCreated = parseInt(data?.device.created as string)
  const showYoctoButton =
    !data?.device.yoctoStatus || (data?.device.yoctoStatus && data?.device.yoctoStatus?.indexOf('NotEligible') > -1)
  const conditions = data?.device.conditions ? JSON.parse(data.device.conditions) : {}
  const keypairTokenQuery = useKeypairTokenQuery(data?.device.id ?? '')

  const ethernetIpAddress = data?.device?.latestHeartbeat?.networks.find(network => network.type === 'Wired')?.ipAddress
  const wifiIpAddressActive = data?.device?.latestHeartbeat?.networks.find(network => network.type === 'Wireless')?.online === 'Online'
  const capturedStatus = capturedFirmwareUpgradeStatus.status
  const latestHeartbeat = data?.device?.latestHeartbeat
  const firmwareUpgradeStatus = latestHeartbeat?.firmwareUpgradeStatus?.status || 'idle'
  const firmwareUpgradeProgress = latestHeartbeat?.firmwareUpgradeStatus?.progress || 0
  useEffect(() => {
    if (firmwareUpgradeStatus !== capturedStatus && firmwareUpgradeStatus) {
      if (capturedStatus === 'installing' && firmwareUpgradeStatus === 'idle') {
        addToast(`Firmware upgrade status: installed`, {
          appearance: 'success',
          autoDismiss: false
        })
        setPollInterval(60000)
      } else if (firmwareUpgradeStatus === 'downloading') {
        addToast(`Firmware upgrade status: ${firmwareUpgradeStatus} ${firmwareUpgradeProgress}%`, {
          appearance: 'success',
          autoDismiss: false
        })
      } else if (firmwareUpgradeStatus === 'installing') {
        addToast(`Firmware upgrade status: installing and rebooting device`, {
          appearance: 'success',
          autoDismiss: false
        })
      } else if (firmwareUpgradeStatus === 'install failed') {
        addToast(`Firmware upgrade status: last ${firmwareUpgradeStatus}`, {
          appearance: 'error',
          autoDismiss: false
        })
        setPollInterval(60000)
      }
      setCapturedFirmwareUpgradeStatus({
        status: firmwareUpgradeStatus || 'idle',
        progress: firmwareUpgradeProgress || 0
      })
    }
  }, [
    firmwareUpgradeStatus,
    firmwareUpgradeProgress,
    capturedStatus,
    setCapturedFirmwareUpgradeStatus,
    setPollInterval,
    addToast
  ])

  const constructKeypairConnectionCommand = (token: string) => (
    <>
      <code># Mac/Linux</code>
      <br />
      <code>VB_SUPPORT_SESSION_TOKEN={token} \</code>
      <br />
      <code>VB_DEVICE_ID={data?.device.id} \</code>
      <br />
      <code>VB_SSH_KEYPAIR_ID={keypairTokenQuery?.data?.device?.keypair?.id} \</code>
      <br />
      <code>VB_IP_ADDRESS={ethernetIpAddress} \</code>
      <br />
      <code>
        <Tab count={1} />
        && curl "https://platform.vestaboard.com/v3.0/keypair-token/$VB_SUPPORT_SESSION_TOKEN/raw" {'>'}{' '}
        /tmp/$VB_SSH_KEYPAIR_ID \
      </code>
      <br />
      <code>
        <Tab count={1} />
        && chmod 600 /tmp/$VB_SSH_KEYPAIR_ID \
      </code>
      <br />
      <code>
        <Tab count={1} />
        && ssh -i /tmp/$VB_SSH_KEYPAIR_ID \
      </code>
      <br />
      <code>
        <Tab count={2} />
        -oHostKeyAlgorithms=+ssh-rsa \
      </code>
      <br />
      <code>
        <Tab count={2} />
        -oPubkeyAcceptedKeyTypes=ssh-rsa \
      </code>
      <br />
      <code>
        <Tab count={2} />
        -oStrictHostKeyChecking=no \
      </code>
      <br />
      <code>
        <Tab count={2} />
        -oAddKeysToAgent=no \
      </code>
      <br />
      <code>
        <Tab count={2} /> -oUserKnownHostsFile=/dev/null \
      </code>
      <br />
      <code>
        <Tab count={2} />
        root@$VB_IP_ADDRESS
      </code>
    </>
  )

  const constructKeypairConnectionCommandWindowsPowerShell = (token: string) => (
    <>
      <code># windows powershell</code>
      <br />
      <code># first cd to downloads</code>
      <br />
      <br />
      <code>CD ~\Downloads</code>
      <br></br>
      <br></br>
      <code>
        # download support key, depending on the network you may need to adjust permissions on this file once downloaded
      </code>
      <br></br>
      <br></br>
      <code>
        curl "https://platform.vestaboard.com/v3.0/keypair-token/{token}/raw" {'>'} tempkey
      </code>
      <br></br>
      <br></br>
      <code># ssh into board</code>
      <br></br>
      <code>
        ssh -i tempkey -o HostKeyAlgorithms=+ssh-rsa -o PubkeyAcceptedKeyTypes=ssh-rsa -o StrictHostKeyChecking=no -o
        AddKeysToAgent=no -o UserKnownHostsFile=/dev/null root@{ethernetIpAddress}
      </code>
    </>
  )

  const constructKeyDeleteCommand = (token: string) => `rm /tmp/${keypairTokenQuery?.data?.device?.keypair?.id}`

  const [refreshingKeypairToken, setRefreshingKeypairToken] = React.useState(false)
  const [showRawHeartbeat, setShowRawHeartbeat] = React.useState(false)

  const refreshKeypairToken = async () => {
    setRefreshingKeypairToken(true)
    await keypairTokenQuery.refetch()
    setRefreshingKeypairToken(false)
  }

  const tableRows = [
    {
      key: 'Device ID',
      value: data?.device.id
    },
    {
      key: 'Tags',
      value: (
        <>
          {data?.device.hardware?.tags?.map(tag => (
            <Chip label={tag} />
          ))}
        </>
      )
    },
    {
      key: 'Network SSID',
      value: data?.device.configurationNetwork?.ssid
    },
    {
      key: 'Network Password',
      value: <code>{data?.device.configurationNetwork?.wpa2Key}</code>
    },
    {
      key: 'Device Type',
      value: data?.device.type || 'Unknown'
    },
    {
      key: 'Device Model',
      value: data?.device ? (
        <DeviceModel id={data?.device?.model?.id || ''} deviceId={data?.device?.id as string} />
      ) : null
    },
    {
      key: 'Pairing Code',
      value: data?.device.pairingCode
    },
    {
      key: 'Frame status',
      value: (() => {
        switch (data?.device?.latestHeartbeat?.maskStatus) {
          case 'MaskOn':
            return 'Frame is on'
          case 'MaskOff':
            return 'Frame is off'
          default:
            return 'Unknown 🤔 (Likely requires firmware update)'
        }
      })()
    },
    {
      key: 'Provisioned On',
      value: (
        <span>
          <FormattedDateTime value={dateCreated} />
          {` (${moment(dateCreated).fromNow()})`}
        </span>
      )
    },
    {
      key: 'Last Contact',
      value: (
        <>
          {data?.device.latestHeartbeat?.created ? (
            <>
              {moment(data?.device.latestHeartbeat?.created).fromNow()} (
              <FormattedDateTime value={data?.device.latestHeartbeat?.created} />)
            </>
          ) : (
            'Never'
          )}
        </>
      )
    },
    {
      key: 'Hardware ID',
      value: data?.device.hardware?.id
    },
    {
      key: 'BOI Serial Number',
      value: data?.device.hardware?.boiSerialNumber ?? '(unknown)'
    },
    {
      key: 'Character Set',
      value: <CharacterSet id={data?.device?.characterSet?.id || ''} deviceId={data?.device?.id as string} />
    },
    {
      key: 'Yocto Status',
      value: (
        <span>
          {showYoctoButton ? (
            <Button color='primary' variant='contained' onClick={() => props.makeEligibleForYocto()}>
              Make eligible for Yocto
            </Button>
          ) : (
            data?.device.yoctoStatus
          )}
        </span>
      )
    },
    {
      key: 'Firmware Version',
      value: (
        <FirmwareVersion
          deviceId={data?.device.id || ''}
          value={
            firmwareUpgradeStatus === 'downloading'
              ? 'Downloading...'
              : firmwareUpgradeStatus === 'installing'
              ? 'Installing and Rebooting'
              : data?.device.firmwareVersion || null
          }
          key={`${data?.device.firmwareVersion}-${firmwareUpgradeStatus}`}
        />
      )
    },
    {
      key: 'Conditions',
      value: (
        <Table>
          {Object.keys(conditions).map(key => (
            <TableRow>
              <TableCell>{key}</TableCell>
              <TableCell>{String(conditions[key])}</TableCell>
            </TableRow>
          ))}
        </Table>
      )
    },
    {
      key: 'MAC Addresses',
      value: (
        <ul>
          {data?.device.macAddresses.map(ma => (
            <li>{ma}</li>
          ))}
        </ul>
      )
    },
    {
      key: 'Local API enablement token',
      value: (
        <Box>
          <TextField multiline value={data?.device?.localApiEnablementToken} />
          <Button
            style={{ marginLeft: '30px' }}
            variant='outlined'
            disabled={
              props.localApiEmailTriggered || !data?.device?.localApiEnablementToken || !data?.device?.boards.length
            }
            onClick={() => {
              props.sendLocalApiToken()
            }}>
            {props.localApiEmailTriggered ? 'Email Sent' : 'Email Local API Token'}
          </Button>
        </Box>
      )
    },
    {
      key: 'Manual upgrade tool',
      value: (
        <Box>
          <TextField
            multiline
            value={`curl https://www.vestaboard.com/hubfs/Firmware%20Files/vb-manual-update --output vb-manual-update && chmod +x vb-manual-update && ./vb-manual-update ${keypairTokenQuery?.data?.device?.keypair?.token} ${ethernetIpAddress}`}
          />
        </Box>
      )
    },
    {
      key: 'Boards',
      value: (
        <Table>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Title</TableCell>
          </TableRow>
          {data?.device.boards.map(board => (
            <TableRow>
              <TableCell>
                <Button variant='outlined' onClick={() => history.push(`/boards/${board.id}`)}>
                  <TruncatedId value={board.id}></TruncatedId>
                </Button>
              </TableCell>
              <TableCell>{board.title}</TableCell>
            </TableRow>
          ))}
        </Table>
      )
    }
  ]

  const versionToDeployName = JSON.parse(firmwareData?.osVersionToDeploy?.metadata || '{}')?.versionName
  const keypairInfo = [
    {
      key: 'Unique-per-device keypair last rotated',
      value: keypairTokenQuery?.data?.device?.keypair?.associated ? (
        <FormattedDateTime value={keypairTokenQuery?.data?.device?.keypair?.associated} />
      ) : (
        'Never'
      )
    },
    {
      key: 'SSH Keypair ID',
      value: <code>{keypairTokenQuery?.data?.device?.keypair?.id}</code>
    },
    {
      key: 'Connect with key command',
      value: keypairTokenQuery?.data?.device?.keypair?.token ? (
        <>
          <Box flex flexDirection='column'>
            <Box style={{ width: 900, padding: 10, border: '1px solid black' }} flex flexGrow={1}>
              {constructKeypairConnectionCommand(keypairTokenQuery?.data?.device?.keypair?.token ?? '')}
            </Box>
            <Box style={{ width: 900, padding: 10, border: '1px solid black' }} flex flexGrow={1}>
              {constructKeypairConnectionCommandWindowsPowerShell(
                keypairTokenQuery?.data?.device?.keypair?.token ?? ''
              )}
            </Box>
            <Box flex>
              <Button onClick={() => refreshKeypairToken()}>
                <ReplayOutlined />
              </Button>
            </Box>
          </Box>
        </>
      ) : null
    },
    {
      key: 'Delete key command',
      value: keypairTokenQuery?.data?.device?.keypair?.token ? (
        <>
          <Box flex flexDirection='column'>
            <Box flex flexGrow={1}>
              <TextField
                style={{ fontFamily: 'monospace', width: '100%', border: '1px solid black' }}
                multiline
                disabled={refreshingKeypairToken}
                value={constructKeyDeleteCommand(keypairTokenQuery?.data?.device?.keypair?.token ?? '')}
              />
            </Box>
          </Box>
        </>
      ) : null
    },
    {
      key: 'Rotate Device Keys (for new firmware only)',
      value: (
        <>
          <Button
            onClick={async () => {
              await refreshDeviceKeys({
                variables: {
                  deviceId: data?.device.id
                },
                refetchQueries: [{ query: DEVICE_QUERY, variables: { deviceId: data?.device.id } }],
                awaitRefetchQueries: true
              })
              addToast('Rotate keys requested', {
                appearance: 'success',
                autoDismiss: true
              })
            }}>
            {'Rotate Device Keys'}
          </Button>
        </>
      )
    },
    {
      key: 'Upgrade Firmware over WebSocket (for new firmware only)',
      value: (
        <>
          <Button
            disabled={
              firmwareUpgradeStatus === 'installing' ||
              firmwareUpgradeStatus === 'downloading' ||
              data?.device.firmwareVersion === versionToDeployName ||
              !firmwareData?.osVersionToDeploy ||
              !JSON.parse(data?.device?.latestHeartbeatRaw || '{}')?.osVersion?.versionName
            }
            key={`${data?.device.firmwareVersion}-${firmwareUpgradeStatus}-upgrade`}
            onClick={async () => {
              setPollInterval(5000)
              await upgradeFirmware({
                variables: {
                  input: {
                    device: data?.device?.id,
                    osVersionId: firmwareData?.osVersionToDeploy?.id
                  }
                }
              })

              addToast('Firmware upgrade requested', {
                appearance: 'success',
                autoDismiss: true
              })
            }}>
            {firmwareUpgradeStatus === 'downloading'
              ? 'Downloading...'
              : firmwareUpgradeStatus === 'installing'
              ? 'Installing and Rebooting'
              : data?.device.firmwareVersion === versionToDeployName
              ? 'Already on latest'
              : `Upgrade to ${versionToDeployName}`}
          </Button>
        </>
      )
    },
    {
      key: 'Forget Device Network',
      value: (
        <>
          <Button
            disabled={!wifiIpAddressActive}
            onClick={async () => {
              props.setConfirmRemoveNetwork(true)
            }}>
            {wifiIpAddressActive
              ? `Send Forget Device Network Command [ssid: ${
                  data?.device?.latestHeartbeat?.networks.find(network => network.type === 'Wireless')?.ssid 
                }]`
              : 'No Wifi Network Found'}
          </Button>
        </>
      )
    },
    {
      key: 'Force Request Heartbeat (for new firmware only)',
      value: (
        <>
          <Button
            onClick={async () => {
              await requestDeviceHeartbeat({
                variables: {
                  deviceId: data?.device.id
                }
              })
              addToast('Heartbeat requested', {
                appearance: 'success',
                autoDismiss: true
              })
              await delay(5000)
              props.refetch && props.refetch()
              setShowRawHeartbeat(true)
            }}>
            {'Force Request a Verbose Heartbeat'}
          </Button>
        </>
      )
    },
    {
      key: 'Raw Heartbeat',
      value: (
        <>
          <Button
            onClick={() => {
              setShowRawHeartbeat(!showRawHeartbeat)
            }}>
            {showRawHeartbeat ? 'Hide Raw Heartbeat' : 'Show Raw Heartbeat'}
          </Button>
          {showRawHeartbeat && data?.device?.latestHeartbeatRaw && (
            <code
              style={{
                whiteSpace: 'pre',
                fontSize: '12px'
              }}>
              {'\n' + JSON.stringify(JSON.parse(data?.device?.latestHeartbeatRaw as string), null, 4)}
            </code>
          )}
        </>
      )
    }
  ]

  if (!props?.data) {
    return <CircularProgress />
  }

  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <Card>
            <Table>
              <TableBody>
                {tableRows.map(row => (
                  <TableRow key={row.key}>
                    <TableCell>
                      <strong>{row.key}</strong>
                    </TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
          {keypairTokenQuery.loading ? (
            <CircularProgress />
          ) : (
            <Card>
              <Table>
                <TableBody>
                  {keypairInfo.map(row => (
                    <TableRow key={row.key}>
                      <TableCell>
                        <strong>{row.key}</strong>
                      </TableCell>
                      <TableCell>{row.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Card className={classes.connectionCard}>
          <ConnectionCard
            deviceId={props.deviceId}
            title='Ethernet'
            icon={SettingsEthernetIcon}
            showSsid={false}
            network={data?.device?.latestHeartbeat?.networks.find(network => network.type === 'Wired')}
            timeago={data?.device?.latestHeartbeat?.timeago || 0}
          />
        </Card>
        <Box className={classes.connectionCardSpacer} />
        <Card>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Container</TableCell>
                <TableCell>Version</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.device.latestHeartbeat?.containers?.map(container => (
                <TableRow>
                  <TableCell>{container.image}</TableCell>
                  <TableCell>{container.version}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
        <Box className={classes.connectionCardSpacer} />

        <Card className={classes.connectionCard}>
          <ConnectionCard
            title='Wifi'
            icon={WifiIcon}
            showSsid={true}
            deviceId={props.deviceId}
            network={data?.device?.latestHeartbeat?.networks.find(network => network.type === 'Wireless')}
            timeago={data?.device?.latestHeartbeat?.timeago || 0}
          />
        </Card>
      </Grid>
    </>
  )
}
