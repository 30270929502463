import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { Confirm, Spacer, useToasts } from '@vestaboard/installables'
import { OS_VERSIONS_QUERY, useOsVersions } from './useOsVersions'
import { useDeployOsVersion, useDisableDeployedOsVersion, useSoftDeleteOsVersion } from './useDeployOsVersion'

import { Check } from '@material-ui/icons'
import { FormattedDateTime } from '../formatted-date-time'
import React from 'react'
import { TruncatedId } from '../truncated-id'
import { useAuth0 } from '../../react-auth0-spa'

export const OsVersionList = () => {
  const { getTokenSilently } = useAuth0()
  const [page] = React.useState(0)
  const [osVersionToDeploy, setOsVersionToDeplpoy] = React.useState('')
  const [osVersionToSoftDelete, setOsVersionToSoftDelete] = React.useState('')

  const [osVersionToDeployName, setOsVersionToDeplpoyName] = React.useState('')
  const [openDisableDeploys, setOpenDisableDeploys] = React.useState(false)

  const { data } = useOsVersions({ page })
  const [deployOsVersion] = useDeployOsVersion()
  const [disableDeploy] = useDisableDeployedOsVersion()
  const [softDeleteOsVersion] = useSoftDeleteOsVersion()

  const { addToast } = useToasts()

  return (
    <>
      <Typography variant='h4'>OS Versions</Typography>
      <Spacer />
      <Card
        style={{
          overflowX: 'scroll'
        }}>
        <Spacer />
        <Box
          style={{
            flexDirection: 'row',
            display: 'flex',
            // flex end
            justifyContent: 'flex-end'
          }}>
          <Button
            onClick={async () => {
              const command = `curl -X POST -d '{"commitSha": "'$VESTABOARD_OS_COMMIT_SHA'", "versionName": "3.1.x", "notes": "", "production": "true" }' -H "x-vestaboard-token: ${await getTokenSilently()}" https://platform.vestaboard.com/v3.0/internal/os-version > metadata.json`
              await navigator.clipboard.writeText(command)
              addToast(`Copied Upload Command.`, {
                appearance: 'success',
                autoDismiss: true
              })
            }}>
            Copy Upload Command
          </Button>
          <Button
            style={{ color: 'red' }}
            onClick={async () => {
              setOpenDisableDeploys(true)
            }}>
            Stop Automatic Deploy Upgrades
          </Button>
        </Box>
        <TableContainer
          style={{
            overflow: 'auto',
            width: '100%'
          }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Version ID</TableCell>
                <TableCell>Version Name</TableCell>
                <TableCell>Version Notes</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Commit SHA</TableCell>
                <TableCell>Production</TableCell>
                <TableCell>Deployed</TableCell>
                <TableCell>Deployed By</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              style={{
                overflow: 'auto'
              }}>
              {data?.osVersions?.map(osVersion => (
                <TableRow key={osVersion.id}>
                  <TableCell>
                    <TruncatedId value={`${osVersion.id}`} path={`/os-version/${osVersion.id}`}></TruncatedId>
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: '200px'
                    }}>
                    {osVersion.metadata ? JSON.parse(osVersion.metadata)?.versionName : ''}
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: '300px'
                    }}>
                    <p>{osVersion.metadata ? JSON.parse(osVersion.metadata)?.notes?.slice(0, 100) : ''}</p>
                  </TableCell>
                  <TableCell>
                    <FormattedDateTime value={osVersion.created}></FormattedDateTime>
                  </TableCell>
                  <TableCell>
                    <a href={`https://github.com/Vestaboard/yocto-vestaboard-os/commit/${osVersion.commitSha}`}>
                      {osVersion.commitSha}
                    </a>
                  </TableCell>
                  {/* @ts-ignore */}
                  <TableCell>{osVersion?.production ? <Check /> : ''}</TableCell>
                  <TableCell>{osVersion?.deploy ? <Check /> : ''}</TableCell>
                  <TableCell>
                    <TruncatedId
                      value={osVersion?.deployedBy?.id ? `${osVersion?.deployedBy?.id}` : ''}
                      path={`/person/${osVersion?.deployedBy?.id}`}></TruncatedId>
                  </TableCell>
                  <TableCell>
                    {/* @ts-ignore */}
                    {osVersion?.production && !osVersion?.deploy ? (
                      <Button
                        onClick={() => {
                          setOsVersionToDeplpoyName(
                            osVersion.metadata ? JSON.parse(osVersion.metadata)?.versionName : ''
                          )
                          setOsVersionToDeplpoy(osVersion.id)
                        }}>
                        Deploy
                      </Button>
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell>
                    {/* @ts-ignore */}
                    {osVersion?.production === false ? (
                      <Button
                        style={{
                          color: 'red'
                        }}
                        onClick={() => {
                          setOsVersionToSoftDelete(osVersion.id)
                        }}>
                        Delete
                      </Button>
                    ) : (
                      ''
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Confirm
          title={`Are you sure you want to set \n${osVersionToDeployName}\n as the latest released firmware?`}
          message={`${osVersionToDeploy} will be the version of firmware all devices will eventually update to.`}
          open={!!osVersionToDeploy}
          handleClose={function (): void {
            setOsVersionToDeplpoyName('')
            setOsVersionToDeplpoy('')
          }}
          handleAccept={async () => {
            try {
              await deployOsVersion({
                variables: { id: osVersionToDeploy, setDeploy: true },
                awaitRefetchQueries: true,
                refetchQueries: [
                  {
                    query: OS_VERSIONS_QUERY,
                    variables: {
                      page: 0,
                      perPage: 1000
                    }
                  }
                ]
              })
              addToast(`OS Version ${osVersionToDeploy.slice(0, 5)} Deployed`, { appearance: 'success' })
              setOsVersionToDeplpoyName('')
              setOsVersionToDeplpoy('')
            } catch (e) {
              console.error(e)
              addToast(`Error deploying OS Version ${osVersionToDeployName} - ${osVersionToDeploy.slice(0, 5)}`, {
                appearance: 'error'
              })
            }
          }}></Confirm>
        <Confirm
          title={`Are you sure you want to delete \n${osVersionToSoftDelete} ?`}
          message={`${osVersionToSoftDelete} will be removed from this list.`}
          open={!!osVersionToSoftDelete}
          handleClose={function (): void {
            setOsVersionToSoftDelete('')
          }}
          handleAccept={async () => {
            try {
              await softDeleteOsVersion({
                variables: { id: osVersionToSoftDelete },
                awaitRefetchQueries: true,
                refetchQueries: [
                  {
                    query: OS_VERSIONS_QUERY,
                    variables: {
                      page: 0,
                      perPage: 1000
                    }
                  }
                ]
              })
              addToast(`OS Version ${osVersionToSoftDelete.slice(0, 5)} Removed`, { appearance: 'success' })
              setOsVersionToSoftDelete('')
            } catch (e) {
              console.error(e)
              addToast(`Error removing OS Version ${osVersionToSoftDelete}`, {
                appearance: 'error'
              })
            }
          }}></Confirm>

        <Confirm
          title={`Are you sure you want to remove automatic deploys?`}
          message={`Mobile apps will stop requesting automatic firmware updates.`}
          open={openDisableDeploys}
          handleClose={function (): void {
            setOpenDisableDeploys(false)
          }}
          handleAccept={async () => {
            try {
              await disableDeploy({
                awaitRefetchQueries: true,
                refetchQueries: [
                  {
                    query: OS_VERSIONS_QUERY,
                    variables: {
                      page: 0,
                      perPage: 1000
                    }
                  }
                ]
              })
              addToast(`Deploy flag removed`, { appearance: 'success' })
              setOpenDisableDeploys(false)
            } catch (e) {
              console.error(e)
              addToast(`Error removing Deploy flag`, {
                appearance: 'error'
              })
            }
          }}></Confirm>
        {/* small table no pagination needed yet */}
        {/* <TablePagination
          rowsPerPageOptions={[]}
          count={totalCount}
          rowsPerPage={perPage}
          page={page}
          onPageChange={(_, page) => setPage(page)}
          onChangeRowsPerPage={() => {}}
        /> */}
      </Card>
    </>
  )
}
