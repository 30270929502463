import { ColorTemplateCategoryForm, IColorTemplateCategoryData } from '../components/ColorTemplateCategoryForm'
import { Confirm, FlexHorizontal, LoadMask, Spacer, useToasts } from '@vestaboard/installables'
import { LinearProgress, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'

import { Alert } from '@material-ui/lab'
import { ColorTemplates } from '../components/ListCategoryColorTemplates'
import { useDeleteColorTemplateCategory } from '../hooks/useDeleteColorTemplateCategory'
import { useGetColorTemplateCategory } from '../hooks/useGetColorTemplateCategory'
import { useUpdateColorTemplateCategory } from '../hooks/useUpdateColorTemplateCategory'

interface RouteParams {
  id: string
}

export const ColorTemplateCategoryUpdate = () => {
  const history = useHistory()
  const { addToast } = useToasts()
  const params = useParams<RouteParams>()
  const [confirmingDelete, setConfirmingDelete] = useState(false)
  const [deleteColorTemplateCategoryMutation, { loading: deleting }] = useDeleteColorTemplateCategory()
  const [updateColorTemplateCategoryMutation, { loading: updating }] = useUpdateColorTemplateCategory()
  const { data, loading, error } = useGetColorTemplateCategory(params.id)
  const onSave = async (colorTemplate: IColorTemplateCategoryData) => {
    const { errors } = await updateColorTemplateCategoryMutation({
      variables: {
        input: {
          id: params.id,
          ...colorTemplate
        }
      }
    })

    if (errors?.length) {
      return addToast('There was an error updating your color template category', {
        appearance: 'error',
        autoDismiss: true
      })
    }

    addToast('Your colo tTemplate category has been updated', {
      appearance: 'success',
      autoDismiss: true
    })
  }

  const deleteColorTemplateCategory = async () => {
    setConfirmingDelete(false)

    const { errors } = await deleteColorTemplateCategoryMutation({
      variables: {
        input: {
          id: params.id
        }
      }
    })

    if (errors?.length) {
      return addToast('There was an error deleting your color template category', {
        appearance: 'error',
        autoDismiss: true
      })
    }

    addToast('Your color template category has been deleted', {
      appearance: 'success',
      autoDismiss: true
    })

    history.push('/color-templates?tab=Categories')
  }

  return (
    <>
      <Confirm
        title='Delete ColorTemplate Category?'
        message='Are you sure you want to delete this colorTemplate category? Chose wisely!'
        open={confirmingDelete}
        handleClose={() => setConfirmingDelete(false)}
        handleAccept={deleteColorTemplateCategory}
      />
      {updating || deleting ? <LoadMask /> : null}
      {error ? (
        <Alert severity='error'>Color template category not found.</Alert>
      ) : loading || !data ? (
        <LinearProgress />
      ) : (
        <>
          <FlexHorizontal spaceBetween>
            <Typography variant='h4'>Update Color Template Category</Typography>
          </FlexHorizontal>
          <Spacer size='large' />
          <ColorTemplateCategoryForm
            key={params.id}
            onSave={onSave}
            onDelete={() => {
              setConfirmingDelete(true)
            }}
            colorTemplateCategory={{
              title: data?.colorTemplateCategory.title || ''
            }}
          />

          <Spacer size='large' />
          <ColorTemplates id={params.id} title={data?.colorTemplateCategory.title || ''} />
        </>
      )}
    </>
  )
}
